
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"


import moduleOrderList from "./modules/order"
import moduleNotification from './modules/notification'
import moduleTransaction from './modules/transaction'
import moduleChatList from "./modules/chat"

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
        orderList:moduleOrderList,
        notification:moduleNotification,
        transaction:moduleTransaction,
        chatList:moduleChatList,
    },
    strict: process.env.NODE_ENV !== 'production'
})

