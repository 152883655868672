
import axios from "@/plugins/axios.js"

const actions = {
  fetchOrders({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDERS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchOrder({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER', response.data.data.order)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {}

const mutations = {
    SET_ORDERS(state, data) {
      state.pageData = data
    },
    SET_ORDER(state, order){
      state.order = order
    },
    UPDATE_ORDER(state, order){
      Object.assign(state.order, order)
      if(state.pageData.data.length){
        const dataIndex = state.pageData.data.findIndex((o) => o.id == order.id);
        Object.assign(state.pageData.data[dataIndex], order);
      }
    },

    /**************** THIS IS FOR DELIVERIES********************/
    UPDATE_DELIVERY(state, item){
      if(state.order.delivery){
        Object.assign(state.order.delivery, item)
      }else{
        state.order.delivery = item
      }
    },
      /**************** THIS IS FOR MESSAGES********************/
    SET_MESSAGES(state, items){
      state.order.delivery.messages = [...items]
    },
    ADD_MESSAGE(state, item){
      state.order.delivery.messages.push(item)
    },
    UPDATE_MESSAGE(state, item){
      const dataIndex = state.order.delivery.messages.findIndex((a) => a.id == item.id);
      Object.assign(state.order.delivery.messages[dataIndex], item);
    },
    DELETE_MESSAGE(state, itemId){
      const itemIndex = state.order.delivery.messages.findIndex((g) => g.id == itemId)
        state.order.delivery.messages.splice(itemIndex, 1)
    },
}

const state = {
    pageData: {
      data: []
    },
    order: {},
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

